.icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin-right: 3px;
    margin-bottom: 5px;
    transition: all 0.3s;
}

.caption-box .right-txt {
    /* float: right; */
    position: absolute;
    right: 25px;
}

.team-details h2 {
    font-size: 15px;
    font-weight: bold;
    margin: 12px 0px;
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 0px;
}

.flex-container div:nth-child(1) {
    font-weight: bold;
}

.details-img {
    width: 200px;
    height: 220px;
}

.details-img img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width:600px) {
    .caption-box {
        width: 100% !important;
    }
}