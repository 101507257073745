.blog-bg .card .blogimg {
    transition: all .2s ease-in-out;
    position: relative;
    top: 0;
}

.blogimg img {
    /* border-radius: 5px; */
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.blog-bg .card,
.blog-card {
    background-color: #3e1211;
    color: #fff;
    height: 330px;
    overflow: hidden;
}

.new-cus-card {
    border: none;
}