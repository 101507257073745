/* header styles */

.navbar-brand img {
  width: 336px;
}

.logo-text.logo-text img {
  height: 35px;
  width: 50px;
  margin: 5px 0px;
}

.logo-text .img-div {
  text-align: center;
}

.logo-text h3 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  /* font-style: italic; */
  text-transform: uppercase;
}

.logo-text h3 span {
  font-size: 20px;
  color: red;
}

.navbar {
  background-color: black;
  color: #fff;
  border-bottom: 3px solid #a20000;
  ;
  position: sticky;
  top: 0%;
  z-index: 1000;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #f50d0d;
}

.navbar-light .navbar-nav .nav-link.active:hover {
  color: #f50d0d;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #f50d0d;
}

.btn-log {
  padding: 3px 20px;
  background-color: #a20000 !important;
  color: #fff;
  /* border: 0.5px solid black; */
  font-weight: 500;
}

.btn-log:hover {
  padding: 3px 20px;
  background-color: #720d0d !important;
  color: #ccc5c5;
  border: 0.5px solid black;
  font-weight: 500;
}

.dropdown-menu {
  box-shadow: 5px 5px 5px #d3c8c83d;
  width: 260px;
  /* background: linear-gradient(210deg, #ecd82a 0%, #462625 100%); */
  background-color: #a20000e0;
  border: 2px solid #720d0d;
  border-radius: 5px;
}

.dropdown-menu li a {
  color: #fff;
  font-size: 17px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
}


.dropdown-menu li a i {
  margin: 0px 7px;
}

.dropdown-menu li a:hover {
  color: #fff;
  font-size: 17px;
  letter-spacing: 0.5px;
  background-color: #000000ce;

}

.dropdown-menu:li {
  text-align: left;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}



/* footer/ */

.footer {
  background-color: #090d0e;
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  padding: 50px;
  /* margin-top: 50px; */
}

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: inline-block;
  vertical-align: top;
}


/* footer left*/

.footer .footer-left {
  width: 33%;
  padding-right: 15px;
}

.footer .footer-left i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer .footer-left p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
}

.footer .footer-left span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer .about {
  line-height: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer .about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer .icons {
  margin-top: 25px;
}

.footer .icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 2px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
  transition: all 0.3s;
}

.footer .icons a:hover {
  background-color: #ee8246;

}


/* footer center*/

.footer .footer-center {
  width: 30%;
}

.footer .footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer .footer-center p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
}

.footer .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer .footer-center p a {
  color: #f58634;
  text-decoration: none;
}


/* footer right*/

.footer .footer-right {
  width: 35%;
}

.map-box iframe {
  width: 100%;
  height: 250px;
}

.footer h2 {
  color: #ffffff;
  font-size: 36px;
  font-weight: normal;
  margin: 0;
}

.footer h2 span {
  color: #0099ff;
}

.footer .menu {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer .menu a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footer .menu a:hover {
  color: #f0803f;
}

.footer .name {
  color: #0099ff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}


@media screen and (max-width: 600px) {
  .navbar-brand {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 0px !important;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
  }

  .navbar-brand img {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {


  .footer {
    font-size: 14px;
  }

  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
  }

  .footer .footer-center i {
    margin-left: 0;
  }


}