.about-container {
    /* background: url('../../../assets/images/H4.png'); */
    background-color: #0b0b0c;
}

.about-title {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
}

.heading-txt {
    color: #fff;
}

.content-box p {
    color: #b4b2b2;
}

.img-boxxx {
    text-align: center;
}

.main-containt h4 {
    color: #fef401;
    font-size: 21px;
    font-weight: bold;
    margin: 25px 0px;
}