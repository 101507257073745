.accordion-container {
    width: 90%;
    max-width: 800px;
    margin: auto;
    margin-top: 30px;
}

.accordion-item {
    background-color: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    margin-bottom: 10px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.accordion-header {
    /* background-color: #6C63FF; */
    background: linear-gradient(210deg, #e9834a 0%, #5c1e1b 100%);
    color: #FFFFFF;
    padding: 15px;
    font-size: 18px;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0 0;
    transition: background-color 0.3s ease;
}

.accordion-header:hover {
    background-color: #5753C9;
}

.accordion-content {
    background-color: #FAFAFA;
    overflow: hidden;
    padding: 0 15px;
    max-height: 0;
    transition: max-height 0.3s ease;
}

.accordion-content p {
    margin: 15px 0;
    line-height: 1.5;
}

.icon {
    transition: transform 0.3s ease;
}

.active .icon {
    transform: rotate(45deg);
}


.cus-ass-card {
    margin-bottom: 15px;

}

.caption-text p {
    margin-bottom: 0;
    font-weight: bold;
}



.user-img {
    text-align: center;
    margin-bottom: 5px;
}

.cus-ass-card img {
    width: 150px;

}