.contact-container {
    /* background: url('../../../assets/images/H4.png'); */
    background-color: #0b0b0c;
}

.contact-bg {
    /* background: linear-gradient(210deg, #e9834a38 0%, #5c1e1b70 100%); */
    /* padding: 50px 0px; */
    background-color: #a2000029;
    height: 312px;
}

.contact-title {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    margin: 25px 0px;
}

.form-group label {
    text-align: start !important;
}

.address-text {
    margin-top: 25px;
    color: #f0dede;
}

.addressone-style h5 {
    color: #ddb749 !important;
    font-weight: bold;
    letter-spacing: 1px;
    /* font-family: cursive; */
}

.addresstwo-style h5 {
    color: #ddb749 !important;
    font-weight: bold;
    letter-spacing: 1px;
    /* font-family: cursive; */
}

.form-group input {
    margin-bottom: 15px;
}

.form-container {
    margin-top: 20px;
}