/* .main-bner {
    position: relative;
    background: url('../../../assets/images/nfssc-banner.jpg');
    background-size: cover;
    height: 250px;
   
} */
.strength-img {
    height: 250px;
}

.marqueetag {
    background-color: #a20000;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    padding-top: 3px;
    /* border-radius: 5px; */
}

.banner-img {
    position: relative;
    top: 0;

}

.carousel-item img {
    height: 400px;
}

.banner-txt {
    position: absolute;
    top: 10%;
    padding: 50px 40px;
    /* left:2%;
     transform: translate(15%, 2%); */

}

.banner-txt h2 {
    color: #fff;
    font-size: 70px;
}

.ogr-txt-clr {
    color: #efefef;
    font-size: 23px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: start;
}

.banner-txt p {
    color: #fff;
    font-size: 25px;
}

.main-banner {
    position: relative;
}

.abt-bg {
    /* background: url('../img/Orange\ Box.png'); */
    background: linear-gradient(210deg, #e9834a 0%, #5c1e1b 100%);

    padding: 50px 0px;
}

.comu-abt p {
    color: #fff;
    font-size: 1.1rem;
    text-align: justify;
}


.our-m {
    /* background: url('../../../assets/images/H4.png'); */
    background-color: #0b0b0c;
}

.cus-btn {
    padding: 5px 30px;
    background-color: #fef401;
    color: #000 !important;
    border: 0.5px solid black;
    font-weight: 600;
    border-radius: 5px;
}

.cus-card {
    text-align: center;
    /* height: 260px; */
    border-radius: 5px;
    position: relative;
    /* width: 50%; */
}

/* 
.cus-card img {
    width: 100%;
    height: 300px;
} */

.cus-image {
    opacity: 1;
    display: block;
    width: 150px;
    height: 150px;
    transition: .5s ease;
    backface-visibility: hidden;
    text-align: center;
    margin: auto;
}

.cus-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.cus-card:hover .cus-image {
    opacity: 0.7;

}

.cus-card:hover .cus-middle {
    opacity: 1;
}

.cus-text {
    background-color: #04AA6D;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
}

.cus-card h2 {
    color: #3f44ad;
    font-size: 25px;
}

.box-1 {
    background-color: #f58634;
    color: #fff;
    padding: 20px;
    margin-bottom: 10px;
    /* width: 300px; */

    align-items: flex-end;
}

.box-1 p {
    color: rgb(251 233 233);

}

.box-2 {
    background-color: #ffcc29;
    color: rgb(57, 55, 55);
    padding: 20px;
    margin-bottom: 10px;
    /* width: 300px; */

}

.usdbox {
    background-color: #f58634;
    color: #fff;
    padding: 20px;
    text-align: center;
    margin-bottom: 10px;
}

.usdbox h4 {

    color: #fbf9f9;

}



/* card style */


/*----  Main Style  ----*/
/* #cards_landscape_wrap-2 {
    text-align: center;
    background: #F7F7F7;
} */

#cards_landscape_wrap-2 .container {
    /* padding-top: 80px;
    padding-bottom: 100px; */
}

#cards_landscape_wrap-2 a {
    text-decoration: none;
    outline: none;
}

#cards_landscape_wrap-2 .card-flyer {
    border-radius: 5px;
}

#cards_landscape_wrap-2 .card-flyer .image-box {
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.50);
    border-radius: 5px;
}

#cards_landscape_wrap-2 .card-flyer .image-box img {
    -webkit-transition: all .9s ease;
    -moz-transition: all .9s ease;
    -o-transition: all .9s ease;
    -ms-transition: all .9s ease;
    width: 100%;
    height: 200px;
}

#cards_landscape_wrap-2 .card-flyer:hover .image-box img {
    opacity: 0.7;
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15);
}

#cards_landscape_wrap-2 .card-flyer .text-box {
    text-align: center;
}

#cards_landscape_wrap-2 .card-flyer .text-box .text-container {
    padding: 30px 18px;
}

#cards_landscape_wrap-2 .card-flyer {
    background: #FFFFFF;
    /* margin-top: 50px; */
    margin: 10px 0px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.40);
}

#cards_landscape_wrap-2 .card-flyer:hover {
    background: #fff;
    box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.50);
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;

    /* margin-top: 50px; */
}

#cards_landscape_wrap-2 .card-flyer .text-box p {
    margin-top: 10px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 14px;
    letter-spacing: 1px;
    color: #000000;
}

#cards_landscape_wrap-2 .card-flyer .text-box h6 {
    margin-top: 0px;
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Roboto Black', sans-serif;
    letter-spacing: 1px;
    /* color: #00acc1; */
    color: #000;
}

.cus-footer-btn {
    background-color: #a20000;
    color: #fff;
}

.cus-footer-btn button {
    border: 0;
    background-color: transparent;
    color: #fff;
}

@media only screen and (max-width: 600px) {

    .counter {
        color: #319B38;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        /* width: 210px; */
        height: 222px;
        padding: 48px 28px 0;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    .contact-bg {
        /* background: linear-gradient(210deg, #e9834a38 0%, #5c1e1b70 100%); */
        background-color: #a2000029;
        padding: 50px 0px;
        height: auto !important;
        margin-bottom: 20px;
    }

    .counter .counter-value {
        color: #fff;
        background: linear-gradient(to right, #0C8412, #319B38);
        font-size: 19px;
        font-weight: bold;
        line-height: 33px;
        width: 76% !important;
        padding: 10px 0 26px;
        position: absolute;
        left: 25px !important;
        bottom: 18px !important;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
    }

    .counter:before,
    .counter:after {
        content: '';
        background: linear-gradient(135deg, #fff, #f1f1f1);
        height: 110px !important;
        width: 110px !important;
        box-shadow: -5px -5px 3px rgba(0, 0, 0, 0.2), -10px -10px #008533;
        transform: translateX(-50%) rotate(45deg);
        position: absolute;
        left: 50%;
        top: 44px;
        z-index: -1;
        transition: all 0.3s ease 0s;
    }

    .counter h3 {
        font-size: 12px !important;
        font-weight: 500;
        text-transform: capitalize;
        margin: 0 0 16px;
    }

    .counter .counter-value {
        color: #fff;
        background: linear-gradient(to right, #0C8412, #319B38);
        font-size: 13px !important;
        font-weight: bold;
        line-height: 33px;
        width: 100%;
        padding: 10px 0 26px;
        position: absolute;
        left: 0;
        bottom: 0;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
    }
}

/* couter css */
.counter {
    color: #319B38;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    width: 100%;
    height: 222px;
    padding: 48px 28px 0;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.counter:before,
.counter:after {
    content: '';
    background: linear-gradient(135deg, #fff, #f1f1f1);
    height: 147px;
    width: 147px;
    box-shadow: -5px -5px 3px rgba(0, 0, 0, 0.2), -10px -10px #008533;
    transform: translateX(-50%) rotate(45deg);
    position: absolute;
    left: 50%;
    top: 44px;
    z-index: -1;
    transition: all 0.3s ease 0s;
}

.counter:before {
    background: linear-gradient(135deg, #00652F 50%, transparent 50%);
    box-shadow: none;
    top: 80px;
}

.counter .counter-icon {
    font-size: 35px;
    line-height: 40px;
    margin: 0 0 12px;
}

.counter h3 {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0 0 16px;
}

.counter .counter-value {
    color: #fff;
    background: linear-gradient(to right, #0C8412, #319B38);
    font-size: 19px;
    font-weight: bold;
    line-height: 33px;
    width: 100%;
    padding: 10px 0 26px;
    position: absolute;
    left: 0;
    bottom: 0;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
}

.counter.orange {
    color: #FB9500;
}

.counter.orange:after {
    box-shadow: -5px -5px 3px rgba(0, 0, 0, 0.2), -10px -10px #ED8F1D;
}

.counter.orange:before {
    background: linear-gradient(135deg, #ED610A 50%, transparent 50%);
}

.counter.orange .counter-value {
    background: linear-gradient(to right, #EA7A00, #F1980C);
}

.counter.blue {
    color: #1B9FD1;
}

.counter.blue:after {
    box-shadow: -5px -5px 3px rgba(0, 0, 0, 0.2), -10px -10px #288EBB;
}

.counter.blue:before {
    background: linear-gradient(135deg, #1167C3 50%, transparent 50%);
}

.counter.blue .counter-value {
    background: linear-gradient(to right, #0784C3, #32AAEE);
}

.counter.red {
    color: #D85411;
}

.counter.red:after {
    box-shadow: -5px -5px 3px rgba(0, 0, 0, 0.2), -10px -10px #BF4C13;
}

.counter.red:before {
    background: linear-gradient(135deg, #A60810 50%, transparent 50%);
}

.counter.red .counter-value {
    background: linear-gradient(to right, #CC2D00, #FC6000);
}

@media screen and (max-width:990px) {
    .counter {
        margin-bottom: 40px;
    }

    /* .counter {
        color: #319B38;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        width: 210px;
        height: 222px;
        padding: 48px 28px 0;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        z-index: 1;
    } */

    .counter .counter-value {
        color: #fff;
        background: linear-gradient(to right, #0C8412, #319B38);
        font-size: 19px;
        font-weight: bold;
        line-height: 33px;
        width: 76% !important;
        padding: 10px 0 26px;
        position: absolute;
        left: 25px !important;
        bottom: 18px !important;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
    }

    .counter:before,
    .counter:after {
        content: '';
        background: linear-gradient(135deg, #fff, #f1f1f1);
        height: 110px !important;
        width: 110px !important;
        box-shadow: -5px -5px 3px rgba(0, 0, 0, 0.2), -10px -10px #008533;
        transform: translateX(-50%) rotate(45deg);
        position: absolute;
        left: 50%;
        top: 44px;
        z-index: -1;
        transition: all 0.3s ease 0s;
    }

    .counter h3 {
        font-size: 12px !important;
        font-weight: 500;
        text-transform: capitalize;
        margin: 0 0 16px;
    }

    .counter .counter-value {
        color: #fff;
        background: linear-gradient(to right, #0C8412, #319B38);
        font-size: 13px !important;
        font-weight: bold;
        line-height: 33px;
        width: 100%;
        padding: 10px 0 26px;
        position: absolute;
        left: 0;
        bottom: 0;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
    }

    #cards_landscape_wrap-2 .card-flyer .text-box .text-container {
        padding: 30px 18px;
        height: 110px;
    }

}

.carousel-item img {
    height: 400px;
    opacity: 0.39;
}

.carousel-caption h5 {
    color: #fff;
    font-size: 25px;
    font-weight: bold;
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #fff !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    height: 50px;
    width: 50px;
    outline: black;
    background-color: rgb(11 11 12);
    background-size: 65%, 65%;
    border-radius: 50%;
    border: 1px solid black;
}

.fullscreen {
    /* position: absolute; */
    z-index: 0;
    object-fit: contain;
    width: 100%;
    height: 400px;
}