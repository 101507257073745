.ogr-txt-clrr {
    color: #efefef;
    font-size: 23px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: start;
}

.use-imgg {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid gray;
    position: relative;
    overflow: hidden;
    border: 2px solid #ab1919;
    background-size: cover;
    margin: auto;
}

.use-imgg img {
    width: 150px;
    height: 150px;
}

.use-imgg2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid gray;
    position: relative;
    overflow: hidden;
    border: 2px solid #ab1919;
    background-size: cover;
    margin: auto;
}

.use-imgg2 img {
    height: 100px;
    width: 100px;

}

.bgcardd {
    background-color: #f38500;
    padding: 10px;
    border-radius: 10px;
    color: #fff;

}

.use-detailss h4 {
    font-weight: 600;
}

.map-bg {
    background-color: black;
}

.cus-map img {}

.cus-map {
    text-align: center;
}

.caption-box .center-txt {
    /* float: right; */
    position: absolute;
    right: 184px !important;
}

.teamImg {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    margin: auto;
    background-size: cover;
    background-position: center;
    border: 4px solid #fff;

}

.custeamCard {
    box-shadow: 0px 0px 10px 10px;
    background-color: rgb(162 0 0);
    ;
    margin: 10px;
    padding: 16px;
    border-radius: 10px;
}

.team-name {
    font-size: 18px;
    font-weight: 800;
    text-transform: lowercase !important;

}

.center-text {
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize !important;
    padding: 6px 0px;
}

.courseInfo {
    text-align: center;
}

/* @media screen and (max-width:600px) {
    .caption-box .center-txt {
        position: absolute;
        right: 25px !important;
    }
} */

@media screen and (max-width:990px) {
    .caption-box .center-txt {
        /* float: right; */
        position: absolute;
        right: 74px !important;
    }
}