.maincus-card {
    /* box-shadow: 0 10px 10px #ddb749, 0 10px 10px #eeece7;
     */
    /* box-shadow: 0px 0px 10px 0px #dd4949; */
}

.nfssc-container {
    /* background: url('../../../assets/images/H4.png'); */
    background-color: #0b0b0c;
}

.cus-program-card {
    text-align: center;
    height: 210px !important;
    border-radius: 5px;
    position: relative;

}

.nfssc-title {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    margin: 25px 0px;
}

.details-box {
    background-color: #a20000;
    padding: 12px 10px;
}

.details-box p {
    color: #fff;
    letter-spacing: 0.6px;
}

.cus-sepret h3 {
    font-size: 12px;
    text-align: start;
    font-weight: 700;
}

.cus-sepret h4 {
    font-size: 12px;
    text-align: start;
    font-weight: 700;
    padding: 4px 0px;
}

.cus-sepret {
    display: flex;
    justify-content: space-between;
}

@media (max-width:600px) {
    .maincus-card {
        margin: 10px 0px;
    }
}