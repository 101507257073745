.icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin-right: 3px;
    margin-bottom: 5px;
    transition: all 0.3s;
}

.caption-box .right-txt {
    /* float: right; */
    position: absolute;
    right: 25px;
}

.team-details h2 {
    font-size: 15px;
    font-weight: bold;
    margin: 12px 0px;
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 0px;
}

.flex-container div:nth-child(1) {
    font-weight: bold;
}

.details-img {
    width: 200px;
    height: 220px;
}

.details-img img {
    width: 100%;
    height: 100%;
}

.list-main-box img {
    width: 120px;
    height: 120px;
    border-radius: 50% !important;

}

.teamImg {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    margin: auto;
    background-size: cover;
    background-position: center;
    border: 4px solid #fff;

}

.custeamCard {
    box-shadow: 0px 0px 10px 10px;
    background-color: rgb(162 0 0);
    ;
    margin: 10px;
    padding: 16px;
    border-radius: 10px;
}

.team-name {
    font-size: 18px;
    font-weight: 800;
    text-transform: lowercase !important;

}

.team-degignation-name {
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize !important;
    padding: 12px 0px;
}

.social-links a {
    margin: 5px 8px;
    color: #000;
    padding: 10px;
    background-color: #fff;
    border-radius: 100%;

}

.social-links a:hover {
    color: #007bff;
}

@media screen and (max-width:600px) {
    .caption-box {
        width: 100% !important;
    }
}