.cus-result {
    margin: 20px 0px;
    padding: 30px;
    border: 1px solid white;
    border-radius: 10px;
    background-color: black;
}

.main-result {
    background-color: #fff;
    /* padding: 30px; */
}

.result-table {

    /* padding: 20px */
}

/* .result-marks table thead tr th {
    border: 1px solid black;
  
} */

.result-table table tbody tr td {
    padding-left: 2px;
    font-size: 14px;
    text-align: start;
    padding: 7px 12px;
    border: none;
    text-transform: capitalize;
    white-space: nowrap;
}

.result-marks {
    /* background-color: #ebebeb; */
    /* padding: 20px; */
    /* margin-top: 10px; */
}

.result-marks table thead tr th {
    /* padding-left: 5px 1px;
    font-size: 14px;
    text-align: start;
    padding: 1px;
    border: none;
    white-space: nowrap; */

    padding-left: 5px 1px;
    font-size: 14px;
    text-align: start;
    padding: 9px 12px;
    border: none;
    white-space: nowrap;
    text-align: center;
    background-color: #871b1b;
    color: #fff;

}

.result-marks table tbody tr td {
    /* padding-left: 5px 1px;
    font-size: 14px;
    text-align: start;
    padding: 5px 1px;
    border: none;
    text-transform: capitalize;
    white-space: nowrap; */

    padding-left: 5px 1px;
    font-size: 14px;
    text-align: center;
    padding: 9px 12px;
    /* border: none; */
    text-transform: capitalize;
    white-space: nowrap;
    background: #a20000e3;
    color: #fff;
    /* margin: -7px 2px; */
    margin: 14px;
}



.result-obtaind-marks table thead tr th {
    padding: 9px 12px !important;
    font-size: 14px;
    text-align: center;
    padding: 1px;
    border: none;
    white-space: nowrap;
    background-color: #871b1b;
    color: #fff;
}

.result-obtaind-marks table tbody tr td {

    padding: 9px 12px !important;
    font-size: 14px;
    text-align: center;
    padding: 5px 1px;
    border: none;
    white-space: nowrap;
    background: #a20000e3;
    color: #fff;
}

.obtaind-sub {
    /* background-color: #ebebeb; */
    /* padding: 20px; */
}

.result-img {
    text-align: center;
    padding: 20px 0px;
}

.result-img img {

    width: 70%;

}