.cus-card-img img {
    height: 300px;
    width: 100%;
}

.caption-box {
    display: flex;
    align-items: center;
    background-color: #fff;
    width: 400px;
    padding: 10px 0px;
    height: 40px;
    grid-gap: 10px;
    margin: 15px auto;
    border-radius: 5px;
    color: #000;
}

.numbox {
    background-color: #a20000;
    color: #fff;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 2px;
}

.person-box img {
    transform: scale(1.5);
}

.caption p {
    margin-bottom: 0;
    padding: 3px 0px;
}

.user-img img {
    width: 100px !important;
}

.caption {
    background-color: #ab1919;
    color: #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
}

.caption p:nth-child(1) {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
}

.caption p:nth-child(2) {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
}

.caption p:nth-child(3) {
    color: #fff;
    font-size: 13px;
    font-weight: 500;
}

.caption p:nth-child(4) {
    color: #fff;
    font-size: 13px;
    font-weight: 500;
}

.use-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid gray;
    position: relative;
    overflow: hidden;
    border: 2px solid #a20000e6;
    background-size: cover;
    margin: auto;
}

.use-img img {
    width: 150px;
    height: 150px;
}

.use-details {}

.bgcard {
    background-color: #a20000e6;
    padding: 10px;
    border-radius: 10px;
    color: #fff;

}

.use-details h4 {
    font-weight: 600;
}

.form-document-bg {
    background: #a2000029;
    padding: 50px 0px;
    height: 244px;
}

.cus-new {
    display: flex;
    align-items: center;
    background-color: #fff;
    width: 100%;
    padding: 10px 0px;
    height: 40px;
    grid-gap: 10px;
    margin: 15px auto;
    border-radius: 5px;
    color: #000;
}

@media screen and (max-width:600px) {
    .cus-new {
        display: flex;
        align-items: center;
        background-color: #fff;
        width: 100%;
        padding: 10px 0px;
        height: 40px;
        grid-gap: 10px;
        margin: 15px auto;
        border-radius: 5px;
        color: #000;
    }
}

/* carousel */


/* .list-main-box {
    border: 10px solid red;
    justify-content: center;
} */