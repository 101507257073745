.firsapplydiv p {
    background-color: #a20000;
    padding: 8px;
    border-radius: 5px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #fff;
}

.card-body h5 {
    font-size: 18px;
    text-align: center;
    margin: 20px 0px;
    font-weight: 600;
    color: #c9c4c4;
}

.cus-applybox {
    /* min-height: 650px !important; */
    min-height: 786px !important;
    margin: 20px 0px;
    background: #3130307e;
    border-radius: 20px;
    box-shadow: 0 10px 10px rgba(248, 248, 248, 0.301), 0 6px 6px rgba(136, 135, 135, 0.23);
}

@media screen and (max-width:990px) {
    .cus-applybox {

        min-height: 993px !important;
        margin: 20px 0px;
        background: #3130307e;
        border-radius: 20px;
        box-shadow: 0 10px 10px rgba(248, 248, 248, 0.301), 0 6px 6px rgba(136, 135, 135, 0.23);
    }
}